import React from 'react'
import { graphql } from 'gatsby'

import './styles/index.css'

import { Post } from '../lib/types'

import SEO from '../components/seo'
import Layout from '../components/layout'
import PostExcerpt from '../components/post-excerpt'

interface Props {
  data: any
}

const BlogPage: React.FC<Props> = ({ data }) => {

  // Get posts from page query (from returned `query` below)
  const posts: Post[] = data.allMarkdownRemark.edges.map(edge => edge.node)

  return (
    <>
      <SEO title="Home" />
      <Layout>

        <div className="p-home">

          {/* Post Grid */}
          <div className="p-home__post-grid">

            {/* Posts Excerpts */}
            {posts.map(post => (
              <PostExcerpt
                key={post.id}
                date={post.frontmatter.date}
                title={post.frontmatter.title}
                coverImage={post.frontmatter.cover}
                slug={post.fields.slug}
                excerpt={post.excerpt}
                tags={post.frontmatter.tags}
              />
            ))}

          {/* END Post Grid */}
          </div>

        </div>

      </Layout>
    </>
  )
}

export default BlogPage

// We filter to get only markdowns for posts
export const query = graphql`
  query {
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }, filter: { fileAbsolutePath: { regex: "/posts/" } }) {
      totalCount
      edges {
        node {
          id
          frontmatter {
            title
            date(formatString: "DD/MM/YYYY")
            tags
            cover {
              childImageSharp {
                fluid(maxWidth: 600) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          fields {
            slug
          }
          excerpt
        }
      }
    }
  }
`