import React from 'react'

import './style.css'

interface Props {
  children: string
  onClick?: () => {}
}

const Tag: React.FC<Props> = ({ children, onClick }) => {
  return (
    <span className="c-tag" onClick={onClick}>{children}</span>
  )
}

export default Tag