import React from 'react'
import { Link } from 'gatsby'
import Img from 'gatsby-image'

import './style.css'

import Title from '../title'
import Tag from '../tag'

interface Props {
  title: string
  date: string
  slug: string
  excerpt: string
  coverImage?: any
  tags: string[]
}

const PostExcerpt: React.FC<Props> = ({ title, date, slug, excerpt, coverImage, tags }) => {
  return (
    <article className="c-excerpt">

      {/* {coverImage && <img className="c-excerpt__image" src={coverImage} alt={title} />} */}
      {coverImage && (
        <Link to={slug}>
          <Img className="c-excerpt__image" fluid={coverImage.childImageSharp.fluid} alt={title} />
        </Link>
      )}

      {/* Date */}
      <time className="c-excerpt__date">{date}</time>

      {/* Title */}
      <h2 className="c-excerpt__title">
        <Link to={slug} className="blink">{title}</Link>
      </h2>


      {/* Excerpt */}
      <p className="c-excerpt__text">{excerpt}</p>

      {/* Tags */}
      {tags.map(tag => (
        <div className="c-excerpt__tag-separator" key={tag}>
          <Tag>{tag}</Tag>
        </div>
      ))}

    </article>
  )
}

export default PostExcerpt